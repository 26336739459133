.doadores-list2 {
    margin-top: 90px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.doadores-list2 article {
    background-color: #fafafa;
    border: 1px solid #dddddd;
    border-radius: 6px;
    margin-bottom: 20px;
    padding: 20px;
    font-size: 16px;
}

.doadores-list2 article p {
    font-size: 12px;
    color: #999999;
    line-height: 24px;
    margin-top: 5px;
}

.doadores-list2 article a {
    height: 42px;
    border-radius: 6px;
    border: 2px solid #1dbbcd;
    background: none;
    margin-top: 10px;
    color: #1dbbcd;
    font-size: 14px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s;
    padding: 10px;
    cursor: pointer;
}

.doadores-list2 article a:hover {
    background: #1dbbcd;
    color: #fff;
}

.doadores-list2 .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;    
}

.doadores-list2 .actions button {
    padding: 10px;
    border-radius: 6px;
    border: 0;
    background-color: #1dbbcd;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}


.doadores-list2 .actions button[disabled] {
    opacity:  0.2;
    cursor: default;
}

.doadores-list2 .actions button:hover {
    opacity:  0.7;
}

.doadores-list2 .actions button[disabled]:hover {
    opacity:  0.2;
}

.appBar {
    position: relative;
    border-width: 1px;
    background-color: #1dbbcd;   
  }

.title {
    margin-left: 20px;
    flex: 1;
}